/**
 * This is to make sure our custom "buttons" have appropriate
 * keyboard controls for WAI-ARIA.
 *
 * Reference: https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-3
 */
export function ariaButtonKeyDown(e: KeyboardEvent): void {
  const el = e.currentTarget as HTMLElement;

  if (e.key === ' ' || e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();

    // Simulate a click event.
    el.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        composed: true
      })
    );
  }
}
