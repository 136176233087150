import { getStaticBaseUrl } from './utils/map';

export const STATIC_BASE_URL_SATELLITE = getStaticBaseUrl(
  'mapbox',
  'satellite-streets-v11'
);

export const AUTOFILL_SKU_TOKEN_PREFIX = '20d01';

export const MAPBOX_DOMAINS = ['mapbox.com', 'mapbox.cn', 'tilestream.net'];
